<template>
    <div class="module" v-if="$auth.user().rights.can_view_subscription">
        <div class="name">
            {{ appNames.find(el => el.application == soft_name).name}}
        </div>
        <div class="module-content">
            <div class="data" v-if="data.package_id">
                <div class="title">{{ selectedPackage.package_name }}</div>
                <div class="price">
                    {{ $t('subscription.price') }}:
                    <span class="old-price" v-if="selectedPackage.old_price_in_euro != selectedPackage.price_in_euro">{{ selectedPackage.old_price_in_euro }}</span>
                    <span class="current">{{ selectedPackage.price_in_euro }} EUR {{ selectedPackage.interval == 'month' ? $t('subscription.monthly') : $t('subscription.yearly') }}  </span>
                </div>
            </div>

            <template v-if="selectedPackage.active_subscription || $auth.user().instance.global_stripe === 1">
                <div class="actions">

                    <button class="btn-tbf white-blue" @click="showModal('renew_subscription', { soft_link: first_link_active })" v-if="data.cancel_at_period_end">
                        <div class="text">{{ $t('subscription.btn-renew')  }}</div>
                    </button>

                    <button class="btn-tbf blue" @click="openModalSubscription()" v-else-if="['new', 'unpaid', 'grace_period'].includes(data.status)">
                        <div class="text">{{ $t('subscription.btn-pay')  }}</div>
                    </button>

                    <button class="btn-tbf white" :class="{disabled: data.cancel_subscription === 1}" @click="data.cancel_subscription ? '' : showModal('cancel_subscription', { soft_link: first_link_active })" v-else>
                        <div class="text">{{ data.cancel_subscription ? $t('subscription.request_sent') : $t('subscription.cancel') }}</div>
                    </button>
                </div>
            </template>
        </div>
        <div class="cancel-subscription" v-if="data.cancel_at_period_end">
            <div class="text">{{ $t('navbar.cancel_subscription_notification', { date: moment(data.cancel_at_period_end).format('DD MMMM YYYY') }) }}</div>
        </div>
        <div class="cancel-subscription" v-else-if="data.status == 'grace_period'">
            <div class="text">{{ $t('navbar.grace_period_notification') }}</div>
        </div>

        <div class="cancel-subscription" v-if="['new', 'unpaid'].includes(data.status)">
            <div class="text">{{ $t('subscription.did_not_pay') }}</div>
        </div>
    </div>
</template>

<script>
    import IconCheck from '@/components/Icons/Check'

    export default {
        data() {
            return {
                loadedPackage: false,
                selectedPackage: '',
                appNames: [
                    {id: 1, name: 'Modul Organigrama', application: 'Organigram' },
                    {id: 2, name: 'Modul Proceduri si Procese', application: 'Procedures'},
                    {id: 3, name: 'Modul Crestere', application: 'Objectives'},
                    {id: 4, name: 'TBF Digital', application: 'digital'},
                ],
				first_link_active: '',
                soft: this.data.application
            }
        },
        props: {
            data: Object,
            soft_name: String
        },
        components: {
            IconCheck
        },
        mounted() {
            switch (this.soft_name) {
                case 'Organigram':
                        this.first_link_active = '/';
                    break;
                case 'Objectives':
                        this.first_link_active = `${process.env.VUE_APP_OBJECTIVE_LINK}/api/`;
                    break;
                case 'Procedures':
                        this.first_link_active = `${process.env.VUE_APP_PROCEDURES_LINK}/api/`;
                    break;
            }

            
            if(this.data.package_id) {
                if( this.$auth.user().instance.global_stripe === 1 ) {
                    this.getSelectedPackage();
                } else {
                    this.getSelectedPackage();
                }

            } else {
                this.loadedPackage = true;
            }
        },
        methods: {
            getSelectedPackage() {
				axios.get(`${this.first_link_active}packages`, { params: { package_id: this.data.package_id } })
                .then(({data}) => {
                    this.selectedPackage = data.data.length ? data.data[0] : '';
                })
            },
			upgradeSubscription(packageData){
				this.$root.$emit('open_modal', 'change_plan_confirmation', { packageData : packageData})
			},
            openModalSubscription(){
				// window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                // return true;
                this.$root.$emit('open_modal', 'change_subscription', {soft: this.data, soft_link: this.first_link_active});
            },
            openChangePackage(type){
                window.location.replace(`${process.env.VUE_APP_CENTRAL_FE_LINK}/price`);
                return true;

                if (type == 'open'){
                    this.changePackage = true
                    this.newSelectedPackage = this.selectedPackage
                } else {
                    this.changePackage = false
                    this.newSelectedPackage = {}
                }
            },
            showModal(type, data = false){
				this.$root.$emit('open_modal', type, data)
			},
        }
    }
</script>
