<template>
    <div class="row-space-tbf">
        <div class="space-left"></div> 
        <div class="content">
			<template v-if="hasNewPackage === 1">
				<div class="new-list-subscriptions m-top">
					<module :data="soft_organigram" soft_name="digital" />
				</div>
			</template>
			<template v-else>
				<div class="alert-section">
                    <div class="alert-tbf blue settings-subscriptions">
                        <div class="data">
                            <div class="title" v-html="$t('subscription.view_new_packages')"></div>
                        </div>
                    </div>
                </div>

				<div class="new-list-subscriptions">
					<module :data="soft_organigram" soft_name="Organigram" v-if="!soft_organigram.is_free"/>
					<module :data="soft_objectives" soft_name="Objectives" v-if="!soft_objectives.is_free"/>
					<module :data="soft_procedures" soft_name="Procedures" v-if="!soft_procedures.is_free"/>
				</div>
			</template>
        </div>
        <div class="space-right"></div>
    </div>
</template>

<script>
	import Module from './Module'

	export default {
		components: {
			Module
		},
		computed: {
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri'); 
			},
			soft_objectives() {
				return this.$store.getters['applications/getApplication']('Obiective'); 
			},
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama'); 
			},
		},
		data() {
			return {
				loaded: false,
                appNames: [
                    {id: 1, name: 'Organigrama', application: 'Organigrama' },
                    {id: 2, name: 'Proceduri si Procese', application: 'Proceduri'},
                    {id: 3, name: 'Crestere', application: 'Obiective'},
                ],
				fe_central_link: process.env.VUE_APP_CENTRAL_FE_LINK,
				first_link_active: '',
				hasNewPackage: this.$auth.user().instance ? this.$auth.user().instance.global_stripe : 0
			}
		},
		async mounted(){
			if( this.soft_organigram.is_active ) { 
				this.first_link_active = '/';
			} else if( this.soft_procedures.is_active ) {
				this.first_link_active = `${process.env.VUE_APP_PROCEDURES_LINK}/api/`;
			} else if( this.soft_objectives.is_active ) { 
				this.first_link_active = `${process.env.VUE_APP_OBJECTIVE_LINK}/api/`;
			}
		},
		methods: {
			showModal(type){
				this.$root.$emit('open_modal', type)
			}
		}
	}
</script>